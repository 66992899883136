.navbar {
  background-color: #12847d;
  height: 80px;
  width: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: #12847d;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 1;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 0px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.nav-text a:hover {
  background-color: #75d0c8;
  color: #ffffff;
}

.nav-menu-item {
  width: 100%;
}

.navbar-toggle {
  background-color: #12847d;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: right;
  padding-right: 20px;
  align-items: center;
}

span {
  margin-left: 10px;
}
