.buttonColor {
  background-color: #b2d1ce;
  color: black;
  font-weight: bold;
}

.submit_contact_form_button {
  margin: 5px 10px;
}

.add_more_contact_form_button {
  margin: 10px;
}
