@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

body,
html,
#root,
.app,
.container {
  width: 100%;
  height: 100%;
}
/*================== CSS FOR AUTH. SECTIONS ==================*/

.drkbg-container {
  width: 100%;
  height: 100%;
  background-color: #11847c;
}

.auth-form-box {
  background-color: #11847c;
  /* width: 50%; */
  height: 50%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 25%;
}

.auth-form {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  color: #11847c;
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
}

form.input > input {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  outline: none;
  border: solid 2px #11847c;
  border-radius: 0.75em;
  color: #11847c;
  width: 100%;
  padding: 12px;
  font-size: 14px;
  background: rgba(255, 255, 255, 1);
  margin-top: 0.5em;
}

.display-none {
  display: none;
}

.submit-button-padding {
  padding-top: 60px;
}

.btn {
  color: #11847c;
  padding: 12px;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}

.btn-sub {
  width: 100%;
  min-width: 100px;
  color: #11847c !important;
  padding: 12px;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  border: solid 2px #76d0c9 !important;
  border-radius: 0.75em !important;
  background: #76d0c9 !important;
  cursor: pointer;
}

a:link {
  color: #11847c;
  text-decoration: none;
}

a:visited {
  color: #11847c;
  text-decoration: none;
}

a:hover {
  color: #76d0c9;
  text-decoration: none;
}

a:active {
  color: #76d0c9;
  text-decoration: none;
}

.page-container {
  margin-left: 20%;
  margin-right: 20%;
  align-items: center;
  justify-content: center;
}

.large-icon {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.table {
  background-color: #b1d8d5;
  font-family: "Monserrat", sans-serif;
  color: #0b3130;
  padding: 2%;
}

.unviewed {
  background-color: #b1d8d5;
  font-family: "Monserrat", sans-serif;
  color: #0b3130;
  padding: 2%;
}

.viewed {
  background-color: #bdbdbd;
  font-family: "Monserrat", sans-serif;
  color: #0b3130;
  padding: 2%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

.dashboard,
.add_status {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.image-logo {
  box-sizing: border-box;
  max-height: 80%;
  max-width: 80%;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  margin: auto;
}

span {
  margin-left: 10px;
}

.cardBoxes {
  border-radius: 10px;
  background: #b2d1ce;
}

.cardPatients {
  border-radius: 10px;
  background: #b2d1ce;
  height: 25vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.nameTitles {
  font-weight: bold;
}

.comments {
  border-radius: 10px;
  background: #b2d1ce;
  height: 9pcs;
}

.RecentReportsBox {
  margin-left: 10px;
  margin-top: 100px;
}

.userBannerName {
  margin-left: 40px;
}

.AvatarBox {
  margin-left: 40px;
}

.SymptomTitles {
  font-weight: bold;
}

.card {
  border-radius: 20px;
}

.avatarPicture {
  border: radius 50%;
}

.Emoti {
  margin: 0px 23px;
}

.MessageIcon {
  margin-left: 10px;
}

.TestedPostive {
  font-weight: bold;
  font-size: 25px;
}

.yesRadio {
  font-weight: bold;
  font-size: 20px;
  margin-left: 10px;
}

.noRadio {
  font-weight: bold;
  font-size: 20px;
  margin-left: 10px;
}

.thebuttons {
  display: flex;
}

.FlagButton {
  border: 1px;
}

/* Added for the dashboard */

.pencilToRight {
  float: right;
}

.virtualReminder {
  color: #40877f;
  font-weight: bold;
}

.virtualReminderBody {
  color: #40877f;
}

.cardBoxes1 {
  border-radius: 10px;
  background: #b2d1ce;
}

.Cards1 {
  width: 83%;
}

.trendAdjust {
  height: 300px;
}

.filterSize {
  font-size: 15px;
  margin-left: -40px;
}

.oneLine {
  display: flex;
}

/* Added for the login page */

.registerLink {
  color: black;
}

/*Disabling the functionality of a anchor tag*/
.colorButtonChange {
  border: 0px;
  padding: 0px;
}

.EmotiButton {
  border-radius: 50px;
  width: 72px;
  padding: 1px;
  margin: 0px;
  border: 0px;
  background: #b2d1ce;
  margin: 0px 23px;
}

.calender {
  width: auto !important;
  margin-top: 10px !important;
}

/* Add Status/ Status Update Button  */

.buttonColor {
  background-color: #b2d1ce;
  color: black;
  font-weight: bold;
}

.submit_status {
  margin: 5px 10px;
}

.success {
  font-weight: bold;
  white-space: nowrap;
}
