.avatarPicture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid;
  margin-left: 20px;
}

.UserName {
  margin-top: 10px;
}

/* Added for the dashboard */

.avatarPicture1 {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 1px solid;
  margin-left: 20px;
}

.sameLine {
  display: flex;
}

.sizeChange {
  width: 50px;
  height: 50px;
}
