.messenger {
  display: flex;
}
.chatMenu {
  flex: 2.5;
  width: 350px;
}
.ContactList {
  display: flex;
  border: none;
  border-bottom: 1px solid;
}
.chatBox {
  flex: 5.5;
  /* moz-box-shadow: inset 0 0 5px #949494;
  webkit-box-shadow: inset 0 0 5px #949494; */
  box-shadow: inset 0 0 5px #949494;
  border-radius: 15px;
  margin-bottom: 30px;
}

.chatBoxBottom {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chatBoxTop {
  height: 500px;
  overflow-y: scroll;
  padding-right: 10px;
}

.chatMessageInput {
  width: 80%;
  padding: 10px;
  border-radius: 5px;
}
.chatSubmitButton {
  width: 70px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #189f94;
  color: white;
  padding: 5px;
}

.chatMenuWrapper,
.chatBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.message {
  display: flex;
  flex-direction: column;
}
.messageBubble {
  display: flex;
}
.messageTop {
  display: flex;
}
.messageText {
  padding: 10px;
  border-radius: 20px;
  background-color: lightgray;
  color: black;
  max-width: 300px;
  margin: 10px 5px 5px 10px;
}
.message.own {
  align-items: flex-end;
}
.message.own .messageText {
  background-color: #12847d;
  color: white;
}

.conversation {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.conversation:hover {
  background-color: #b1d8d5;
}
.conversationImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin: 10px 5px 10px 10px;
}
.conversationName {
  font-weight: bold;
}
.conversationTimestamp {
  top: 8px;
  right: 16px;
  font-size: 10px;
  font-weight: 50;
  margin-left: 10px;
  margin-right: 10px;
  color: #949494;
}
