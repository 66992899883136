* {
  margin: 0;
  padding: 0;
}

body {
  background: #fcfcfc;
}

.container {
  width: 100%;
}

.list-item {
  align-items: center;
  display: grid;
  padding: 5px 0px;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
}
.patient-content {
  width: 150px;
  margin-left: 20px;
}
